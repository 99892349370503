const { hostname, port, protocol } = window.location;

const getBaseUrl = () => {
  switch (hostname) {
    case 'painel.redevistorias.com.br':
    case 'painel.sandbox.redevistorias.com.br':
    case 'painel.dev.redevistorias.com.br':
      return `${protocol}//${hostname}`;
    default:
      return 'https://painel.dev.redevistorias.com.br';
  }
};

export const BASE_URL = getBaseUrl();

const getEnv = (url = BASE_URL) => {
  if (url.includes('.dev')) {
    return 'development';
  }

  if (url.includes('.sandbox')) {
    return 'sandbox';
  }

  return 'production';
};

export const ENV = getEnv();

const getDashboardBaseUrl = env => {
  if (env === 'production') {
    return 'https://dashboard.redevistorias.com.br';
  }

  if (env === 'sandbox') {
    return 'https://dashboard.sandbox.redevistorias.com.br';
  }

  return 'https://dashboard.dev.redevistorias.com.br';
};

const getRvGroupClientPanelBaseUrl = env => {
  if (env === 'production') {
    return 'https://painel.app.somosgruporv.com.br';
  }

  if (env === 'sandbox') {
    return 'https://painel.sandbox.app.somosgruporv.com.br';
  }

  return 'https://painel.develop.app.somosgruporv.com.br';
};

const getCommercialBaseUrl = env => {
  if (env === 'production') {
    return 'https://comercial.redevistorias.com.br/api';
  }

  if (env === 'sandbox') {
    return 'https://comercial.sandbox.redevistorias.com.br/api';
  }

  return 'https://comercial.dev.redevistorias.com.br/api';
};

const getCommercialPanelBaseUrl = env => {
  if (env === 'production') {
    return 'https://comercial.redevistorias.com.br';
  }

  if (env === 'sandbox') {
    return 'https://comercial.sandbox.redevistorias.com.br';
  }

  return 'https://comercial.dev.redevistorias.com.br';
};

export const REDIRECT_URL = `${protocol}//${hostname}:${port}`;

export const RV_ADDRESS_API_BASE_URL = BASE_URL.replace('painel', 'address').replace('http:', 'https:');

export const PT_ADDRESS_API_BASE_URL = 'https://json.geoapi.pt/';

export const AUTH_BASE_URL = BASE_URL.replace('painel', 'auth');

export const API_BASE_URL = hostname === 'localhost' ? BASE_URL.replace('painel', 'api') : `${BASE_URL}/api`;

export const API_COMMERCIAL_BASE_URL = getCommercialBaseUrl(ENV);

export const APP_COMMERCIAL_PANEL_BASE_URL = getCommercialPanelBaseUrl(ENV);

export const WEBSOCKET_BASE_URL = BASE_URL.replace('painel', 'painel').replace('https:', 'wss:');

export const DASHBOARD_BASE_URL = getDashboardBaseUrl(ENV);

export const RV_GROUP_CLIENT_PANEL_BASE_URL = getRvGroupClientPanelBaseUrl(ENV);

export const DIVERGENCE_PANEL_URL = `${BASE_URL}/divergences`;

export const SUPPORT_PANEL_URL = `${BASE_URL}/support`;

export const FORBIDDEN_REDIRECT_URL = 'https://conteudo.somosgruporv.com.br/solucoes-gruporv?redirectApp=redevistorias';

export const APP_REDE_IZEE_URL =
  ENV === 'production' ? 'https://app.izee.com.br/auth' : 'https://app.develop.izee.com.br/auth';

export const APP_CLIENT_ACCOUNT_URL =
  ENV === 'production' ? 'https://painel.app.somosgruporv.com.br' : 'https://painel.develop.app.somosgruporv.com.br';

export const APP_REDE_SEGUROS_URL =
  ENV === 'production'
    ? 'https://seguros.redevistorias.com.br/auth-validate'
    : 'https://app.develop.seguros.redevistorias.com.br/auth-validate';

export const MOVIDESK_APP_ID = 'B9B925830D744D6DAE29F46031CBB82E';

export const FEATURE_BAR_REDIRECT_LINK = 'https://conteudo.somosgruporv.com.br/landing-page-guia-completo-novos-planos';

export const CLIENT_FORM_TITLE = 'Pesquisa de satisfação Grupo RV/2023';

export const CLIENT_FORM_URL =
  'https://forms.office.com/Pages/ResponsePage.aspx?id=7SXP5Wfkb0msuWMCdPEtNPw516KlkcpLidzd4F2_85VUMElGUjJGSDRLWDk3QjJVSzg5QkQ2QllGRC4u&ra0f0a6e05efe4b4cb79a903bb666b926={clientId}';

export const GOOGLE_MAPS_BASE_URL = 'https://www.google.com/maps/search';

const config = {
  BASE_URL,
  ENV,
  RV_ADDRESS_API_BASE_URL,
  PT_ADDRESS_API_BASE_URL,
  AUTH_BASE_URL,
  API_BASE_URL,
  WEBSOCKET_BASE_URL,
  APP_REDE_IZEE_URL,
  APP_REDE_SEGUROS_URL,
  APP_CLIENT_ACCOUNT_URL,
  RV_GROUP_CLIENT_PANEL_BASE_URL,
  FEATURE_BAR_REDIRECT_LINK,
  API_COMMERCIAL_BASE_URL,
  CLIENT_FORM_URL,
  CLIENT_FORM_TITLE,
  GOOGLE_MAPS_BASE_URL,
};

export default config;
